.popup-chat {
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 10000000000;
  }
  
  .popup-chat.open {
    transform: translateX(0);
    width: 340px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: 0.3s ease;
    overflow: auto;
  }

  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #6A58C6;
    color: #fff;
    padding: 10px;
    border-radius: 8px 8px 0 0;
    width: 100%;
  }
  
  .chat-header h2 {
    margin: 0;
  }
  
  .close-chat {
    color: #fff;
  }
  
  
  .toggle-button {
    /* background-color: #000;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer; */
    align-self: flex-end;
  }
  
  .chat-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0px 2px;
  }
  
  .message-container {
    flex: 1;
    overflow-y: auto;
    margin-top: 20px;
    padding: 5px;
  }
  
  .message {
    background-color: #f2f2f2;
    border-radius: 8px;
    padding: 8px 12px;
    margin-bottom: 8px;
    max-width: 80%;
    word-wrap: break-word;
  }
  
  .input-container {
    display: flex;
    align-items: center;
    margin-top: auto;
    padding: 2px;
  }
  
  .input-container input {
    flex: 1;
    height: 30px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    margin-right: 10px;
    width: 85%;
  }
  
  .input-container button {
    background-color: #6A58C6;
    color: #fff;
    border: none;
    cursor: pointer;
    width: 15%
  }
  .input-container button:hover {
    background-color: #000;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  .loader, .loader:before, .loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: bblFadInOut 1.8s infinite ease-in-out;
  }
  .loader {
    color: #000;
    font-size: 7px;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;
  }
  .loader:before,
  .loader:after {
    content: '';
    position: absolute;
    top: 0;
  }
  .loader:before {
    left: -3.5em;
    animation-delay: -0.32s;
  }
  .loader:after {
    left: 3.5em;
  }
  
  @keyframes bblFadInOut {
    0%, 80%, 100% { box-shadow: 0 2.5em 0 -1.3em }
    40% { box-shadow: 0 2.5em 0 0 }
  }
      
  
  
  
  /* Chat button animation */
.animate-icon {
  animation: bounce 1s ease-in-out infinite;
}

/* Keyframe for the bounce animation */
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

/* Red badge for the chat icon button */
.toggle-button {
  position: relative;
}

.badge {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 12px;
  height: 12px;
  background-color: red;
  border-radius: 50%;
  display: block;
  border: 2px solid #fff;
}

/* PopupChat.css */
.popup-chat .render-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.category-button, .question-button, .feedback-button {
  background: #6A58C6;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  transition: background-color 0.3s;
}

.category-button:hover, .question-button:hover, .feedback-button:hover {
  background-color: #5a4ab1;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.input-field {
  width: 90%;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.message-input {
  width: 80%;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.send-button {
  background-color: #6A58C6;
  color: white;
}

.send-button:hover {
  background-color: #5a4ab1;
}

.chat-content .message-container {
  /* max-height: 300px; */
  overflow-y: auto;
  padding: 10px;
}

.message {
  background-color: #f1f1f1;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
}

/* PopupChat.css */

/* Container for content */
.popup-chat .render-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 3 items per row */
  gap: 2px; /* Adjust space between items */
  width: 100%;
}

.grid-item {
  background-color: #6A58C6;
  color: white;
  border: none;
  padding: 5px;
  margin: 1px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 12px;
  text-align: center;
  transition: background-color 0.3s ease;
  box-sizing: border-box;
}

.grid-item:hover {
  background-color: #5a4ab1;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.input-field {
  width: 90%;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.message-input {
  width: 80%;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.send-button {
  background-color: #6A58C6;
  color: white;
}

.send-button:hover {
  background-color: #5a4ab1;
}

.chat-content .message-container {
  max-height: 300px;
  overflow-y: auto;
  padding: 10px;
}

.message {
  background-color: #f1f1f1;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
}

.message-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.message {
  padding: 0.75rem 1rem;
  border-radius: 1rem;
  max-width: 80%;
  word-wrap: break-word;
}

.bot-message {
  background-color: #f0f0f0;
  align-self: flex-start;
  border-bottom-left-radius: 0.25rem;
  color: #333;
}

.user-message {
  background-color: #6A58C6;
  color: white;
  align-self: flex-end;
  border-bottom-right-radius: 0.25rem;
}