.image-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    animation: fadeIn 2s ease-in-out;
  }
  
  .image-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 20px;
  }
  .image-box-2{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .rounded-image {
    /* z-index: 999; */
    width: 100%;
    /* height: 250px; */
    border-radius: 25px;
    margin: 20px 0 20px 20px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    
  }
  .rounded-image-1 {
    /* z-index: 999; */
    width: 100%;
    height: 250px;
    border-radius: 25px;
    margin: 20px 0 0px 20px;
    /* box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px; */
    
  }
  .rounded-image:hover{
    animation: wiggle 2s linear infinite;
    /* animation: lr-bar 750ms;
    animation-fill-mode: both;
    animation-delay: 1500ms;
    animation-timing-function: cubic-bezier(0.500, 0.250, 0.500, 0.750); */
    /* border-radius:0;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(9, 55, 53, 0.08), 0px 16px 24px rgba(9, 55, 53, 0.1), 0px 24px 32px rgba(9, 55, 53, 0.14);
  } */
}
  .rounded-image-2:hover{
    animation: wiggle 2s linear infinite;
  }
  .rounded-image-1:hover{
    animation: wiggle 2s linear infinite;
  }
  .rounded-image-2{
    width: 90%;
    /* height: 250px; */
    border-radius: 25px;
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }
  .rounded-image-large{
    width: 50%;  
    border-radius: 25px;
    margin: 20px 20px 20px 0;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }
  .rounded-image-large:hover{
    animation: wiggle 2s linear infinite;
  }
  .rounded-image-small {
    width: 45%;
    border-radius: 25px;
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }
  .rounded-image-small:hover{
    animation: wiggle 2s linear infinite;

  }
  .animation-1 {
    transform: translatey(0px);
    animation: float 5s ease-in-out infinite;
  }
  .animation-2 {
    transform: translatey(0px);
    animation: float 6s ease-in-out infinite;
  }
  /* .rounded-image-2 :hover{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transform: scale(1.3);
  }
  .rounded-image-large :hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transform: scale(1.3);
  }
  .rounded-image-small :hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transform: scale(1.3);
  } */
  @keyframes float {
    0% {
      box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
      transform: translatey(0px);
    }
    50% {
      box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
      transform: translatey(-20px);
    }
    100% {
      box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
      transform: translatey(0px);
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes wiggle {
    0%, 7% {
      transform: rotateZ(0);
    }
    15% {
      transform: rotateZ(-15deg);
    }
    20% {
      transform: rotateZ(10deg);
    }
    25% {
      transform: rotateZ(-10deg);
    }
    30% {
      transform: rotateZ(6deg);
    }
    35% {
      transform: rotateZ(-4deg);
    }
    40%, 100% {
      transform: rotateZ(0);
    }
  }
  @keyframes lr-bar {
    0% { transform-origin: center left 0px; transform: scaleX(0); }
    36% { transform: scaleX(1); transform-origin: center left 0px; }
    54% { transform: scaleX(1); transform-origin: center right 0px; }
    90% { transform: scaleX(0);  transform-origin: center right 0px; }
    100% { transform: scaleX(0);  transform-origin: center right 0px; }
  }
  

  @media screen and (max-width: 600px)  {
    .image-container {
      width: 100%;
      margin-top: 0;
      display: flex;
      justify-content: center;
      align-items: center;

    }
    .image-box-2{
    }
    .rounded-image {
      width: 90%;
      /* height: 250px; */
      border-radius: 25px;
      margin: 20px 0 0px 20px;
  
    }
    .rounded-image-2{
      width: 100%;
      /* height: 250px; */
      border-radius: 25px;
    }
    .rounded-image-large{
      width: 70%;  
      border-radius: 25px;
    }
    .rounded-image-small{
      width: 65%;
      border-radius: 25px;
    }
  }